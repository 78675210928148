import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../App";
import { getCurrentDaysArr } from "../../Helpers/Helpers";
import MenuMask from "../FrontPage/MenuMask";
import Ticket from "../FrontPage/Ticket";
import DateSelector from "./DateSelector";
import DetailedOdds from "./DetailedOdds";
import Menu from "./Menu";
import "./Sport.css";
import SportContent from "./SportContent";

const Sport = (props) => {
  const consumer = useContext(GeneralContext);

  const [shortDays, setShortDays] = useState(null);

  useEffect(() => {
    setShortDays(getCurrentDaysArr("RO-ro"));
  }, []);

  const formatDateSelector = (index) => {
    return (
      shortDays && (
        <span style={{ fontWeight: 600 }}>
          {shortDays.shortDays[index]}{" "}
          <span style={{ fontWeight: 300 }}>
            {shortDays.dayNmonth[index].day +
              "." +
              shortDays.dayNmonth[index].month}
          </span>
        </span>
      )
    );
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuOpen = () => {
    setDateMenuOpen(false);
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [mobileTicketOpen, setMobileTicketOpen] = useState(false);
  const handleMobileTicketOpen = () => setMobileTicketOpen(!mobileTicketOpen);

  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const handleDateMenuOpen = () => {
    setMobileMenuOpen(false);
    setDateMenuOpen(!dateMenuOpen);
  };

  useEffect(() => {
    console.log(consumer.serial[0]);
  }, []);

  return (
    <>
      <div className="rooooot">
        <div className="sport_root">
          <div className="sport_menu">
            <Menu />
          </div>
          <div className="sport_content">
            {consumer.allOddsForEvent[0] != null ? (
              <DetailedOdds />
            ) : (
              <SportContent />
            )}
          </div>
          <div className="sport_ticket">
            <Ticket />
          </div>
        </div>
      </div>

      <div className="mobile_sport_root">
        <div className="mobile_sport_selectors">
          <div
            className="mobile_sport_selected_sport"
            onClick={() => handleMobileMenuOpen()}
          >
            {consumer.activeMenuItemSport[0].name}
          </div>
          <div
            className="mobile_sport_selected_date"
            onClick={() => handleDateMenuOpen()}
          >
            {consumer.dateSelector[0].indexOf(1) === 0
              ? "Astazi"
              : formatDateSelector(consumer.dateSelector[0].indexOf(1))}
          </div>
        </div>

        <div className="mobile_sport_content">
          {consumer.allOddsForEvent[0] != null ? (
            <DetailedOdds />
          ) : (
            <SportContent />
          )}
        </div>

        <div
          className="mobile_frontpage_ticket"
          onClick={() => handleMobileTicketOpen()}
        >
          <div className="mobile_frontpage_ticket_events">
            {consumer.ticketContent[0] != null
              ? consumer.ticketContent[0][0].maxSystem
              : "0"}
          </div>
          <div className="mobile_frontpage_ticket_rows">
            <div className="mobile_fp_ticket_row_1">
              <div className="mobile_frontpage_ticket_label1">Cota</div>
              <div className="mobile_frontpage_ticket_label2">Miza</div>
              <div className="mobile_frontpagE_ticket_label3">
                Castig potential
              </div>
            </div>
            <div className="mobile_fp_ticket_row_2">
              <div className="mobile_frontpage_ticket_label1">
                {consumer.ticketContent[0] != null
                  ? consumer.ticketContent[0][0].cursTotal.toFixed(2)
                  : "0"}
              </div>
              <div className="mobile_frontpage_ticket_label2">
                {consumer.ticketStake[0]} <span>RON</span>
              </div>
              <div className="mobile_frontpagE_ticket_label3">
                {consumer.ticketContent[0] != null
                  ? consumer.ticketContent[0][0].castigMax
                  : "0"}{" "}
                <span>RON</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="mobile_frontpage_menu_mask">
          <Menu handleMobileMenuOpen={handleMobileMenuOpen} />
        </div>
      )}
      {dateMenuOpen && (
        <div className="mobile_frontpage_menu_mask">
          <DateSelector handleDateMenuOpen={handleDateMenuOpen} />
        </div>
      )}
      {mobileTicketOpen && (
        <div className="mobile_ticket_opened">
          <Ticket handleMobileTicketOpen={handleMobileTicketOpen} />
        </div>
      )}
    </>
  );
};

export default Sport;
