import { Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../App";
import TicketLine from "../Pages/FrontPage/TicketLine";
import "./Modal.css";
import Barcode from "react-barcode";
const ModalTicket = (props) => {
  const consumer = useContext(GeneralContext);

  const [ticketData, setTicketData] = useState(null);
  const [ticketContent, setTicketContent] = useState(null);
  useEffect(() => {
    if (consumer.placeBetResponse[0]) {
      setTicketData(consumer.placeBetResponse[0][0]);

      let aux = [];
      consumer.placeBetResponse[0].map((el, i) => {
        if (i > 0) {
          aux.push(el);
        }
      });

      setTicketContent(aux);
    }
  }, [consumer.placeBetResponse[0]]);

  return (
    ticketData && (
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="modal_ticket_root"
      >
        <div className="modal_ticket_container">
          <div className="modal_ticket_header">
            <div className="modal_ticket_titles">
              <div className="modal_ticket_sport">
                SPORT | {ticketData.nrVariante === 1 ? "SIMPLU" : "SYSTEM"}
              </div>
              <div
                className="modal_ticket_close"
                onClick={() => {
                  props.onClose();
                  consumer.placeBetResponse[1](null);
                }}
              >
                X
              </div>
            </div>
          </div>
          <div className="modal_ticket_events">
            <div className="modal_ticket_event">
              Evenimente: <span>{ticketData.maxSystem}</span>
            </div>
            <div className="modal_ticket_totalodd">
              Cota totala: <span>{ticketData.cursTotal}</span>
            </div>
          </div>
          <div className="modal_ticket_content">
            {ticketContent && (
              <div className="modal_ticket_left">
                {ticketContent.map((elem, key) => {
                  return (
                    <>
                      <br />
                      <TicketLine
                        event={elem}
                        stake={consumer.spStake[0]}
                        setStake={consumer.spStake[1]}
                        key={key}
                        for="modal"
                      />
                      <br />
                    </>
                  );
                })}
              </div>
            )}
            <div className="modal_ticket_right">
              <div className="modal_ticket_barcode">
                {consumer.serial[0] && <Barcode value={consumer.serial[0]} />}
              </div>
              <div className="modal_ticket_informations">
                <div className="modal_ticket_total_odd">
                  Cota totala: <span>{ticketData.cursTotal}</span>
                </div>
                <div className="modal_ticket_system_inf">
                  System:{" "}
                  <span>
                    {ticketData.correctSystem === ""
                      ? ticketData.systemWinInfo
                      : ticketData.correctSystem + "/" + ticketData.maxSystem}
                  </span>
                </div>
                <div className="modal_ticket_miza_inf">
                  Miza: <span>{ticketData.sumaBilet} RON</span>
                </div>
                <div className="modal_ticket_tax_cda">
                  Tax CDA:
                  <span>
                    {(ticketData.sumaBilet - ticketData.mizaBilet).toFixed(2)}{" "}
                    RON
                  </span>
                </div>
                <div className="modal_ticket_miza_after">
                  Miza dupa aplicarea taxei:{" "}
                  <span>{ticketData.mizaBilet} RON</span>
                </div>
                <div className="modal_ticket_min_win">
                  Casting potential min. <span>{ticketData.castigMin} RON</span>
                </div>
              </div>

              <div className="modal_ticket_buttons">
                <div className="modal_ticket_max_win">
                  Castig potential: <span>{ticketData.castigMax} RON</span>
                </div>
                <div className="modal_ticket_btns">
                  <div
                    className="modal_ticket_btn_close"
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    INCHIDE
                  </div>
                  <div
                    className="modal_ticket_btn_print"
                    onClick={() => {
                      let pr = document.querySelector(
                        ".modal_ticket_container"
                      ).innerHTML;
                      let a = window.open("", "", "height=810, width=500");
                      a.document.write("<html>");
                      a.document.write("<body >");
                      a.document.write(pr);
                      a.document.write("</body></html>");
                      a.document.close();
                      a.print();
                    }}
                  >
                    PRINTARE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default ModalTicket;
