import React, { useContext, useEffect, useState } from "react";
import {
  GetAuxTicketSerial,
  PlaceBet,
  VerifyTicket,
} from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import TicketLine from "./TicketLine";

const Ticket = (props) => {
  const consumer = useContext(GeneralContext);

  const [ticketSerial, setTicketSerial] = useState(null);

  const [selectedPill, setSelectedPill] = useState([1, 0]);
  const toggleSelectedPill = (idx) => {
    let aux = [0, 0];
    aux[idx] = 1;
    setSelectedPill(aux);
  };

  useEffect(() => {
    if (selectedPill.indexOf(1) === 0) {
      consumer.combArray[1]([]);
    }
  }, [selectedPill]);

  // useEffect(() => {
  //   console.log(consumer.ticketContent[0]);
  // }, [consumer.ticketContent[0]]);

  const renderTicketLines = () => {
    if (consumer.ticketContent[0] == null) return "Biletul este gol";
    else
      return consumer.ticketContent[0].map((element, key) => {
        if (key > 0)
          return (
            <TicketLine
              event={element}
              stake={consumer.spStake[0]}
              setStake={consumer.spStake[1]}
              key={key}
              cancelTicket={cancelTicket}
            />
          );
      });
  };

  const cancelTicket = () => {
    consumer.spStake[1](null);
    consumer.ticketContent[1](null);
    consumer.combArray[1]([]);
  };

  const generateCheckboxes = (howMany) => {
    let defaultChecked = [];

    for (let i = 0; i < howMany; i++) {
      defaultChecked.push(
        <div className="ticket_combinations_single_elem" key={i}>
          <input
            type="checkbox"
            id={i}
            name={"checkbox" + (i + 1)}
            className="ticket_combinations_inpus"
            onChange={() => {
              addOrRemoveCheckbox(i + 1, consumer.combArray[1]);
            }}
            checked={consumer.combArray[0].indexOf(i + 1) > -1 ? true : false}
          />
          <label htmlFor={"checkbox" + i}>
            {i + 1}/
            {consumer.ticketContent[0]
              ? consumer.ticketContent[0].length - 1
              : consumer.spStake[0].eventsArray.split(",").length - 1}
          </label>
        </div>
      );
    }

    return defaultChecked;
  };

  const addOrRemoveCheckbox = (index, setter) => {
    let idx = -1;
    let aux = [...consumer.combArray[0]];
    if (consumer.combArray[0].indexOf(index) > -1) {
      idx = consumer.combArray[0].indexOf(index);
      aux.splice(idx, 1);
      setter(aux);
    } else {
      aux.push(index);
      setter(aux);
    }
  };

  const [verifSerial, setVerifSerial] = useState(null);
  const handleSerialChange = (e) => setVerifSerial(e.target.value);

  return (
    <div className="ticket_root">
      <div className="ticket_verif">
        <div className="ticket_verif_label">Verificare bilet</div>
        <input
          type="text"
          placeholder="Cod bilet"
          className="ticket_verif_input"
          onChange={(e) => handleSerialChange(e)}
        />
        <div
          className="ticket_verif_button"
          onClick={() => VerifyTicket(verifSerial, consumer.verifyResponse[1])}
        >
          &#62;
        </div>
      </div>

      <div
        className="mobile_ticket_close"
        onClick={() => props.handleMobileTicketOpen()}
      >
        <img src="./downarr.png" width="21px" height="21px" />
        <span>Inchide</span>
      </div>

      <div className="ticket_ticket_root">
        <div className="ticket_t_header">BILET VIRTUAL</div>
        <div className="ticket_t_pills">
          <div
            className={
              selectedPill.indexOf(1) === 0
                ? "ticket_t_pills_simplu_selected"
                : "ticket_t_pills_simplu"
            }
            onClick={() => toggleSelectedPill(0)}
          >
            SIMPLU
          </div>
          <div
            className={
              selectedPill.indexOf(1) === 1
                ? "ticket_t_pills_combinat_selected"
                : "ticket_t_pills_combinat"
            }
            onClick={() => toggleSelectedPill(1)}
          >
            COMBINAT
          </div>
        </div>
        <div className="ticket_t_actions">
          <div className="ticket_t_events">
            Evenimente:{" "}
            {consumer.ticketContent[0] != null
              ? consumer.ticketContent[0][0].maxSystem
              : "0"}
          </div>
          <div className="ticket_t_anuleaza" onClick={() => cancelTicket()}>
            <div className="ticket_t_anuleaza_label">Anuleaza</div>
            <img
              src="./bin.png"
              width="12px"
              height="12px"
              className="ticket_t_anuleaza_icon"
            />
          </div>
        </div>
        <div className="ticket_t_content">{renderTicketLines()}</div>
        {selectedPill.indexOf(1) === 1 && consumer.spStake[0] && (
          <div className="ticket_t_combinations">
            {consumer.ticketContent[0]
              ? generateCheckboxes(consumer.ticketContent[0].length - 1)
              : generateCheckboxes(
                  consumer.spStake[0].eventsArray.split(",").length - 1
                )}
          </div>
        )}

        {consumer.ticketContent[0] != null && (
          <div className="ticket_t_stake">
            <div className="ticket_t_total_odd">
              <div className="ticket_total_odd_label">Cota totala:</div>
              <div className="ticket_total_odd_value">
                {consumer.ticketContent[0][0].cursTotal.toFixed(2)}
              </div>
            </div>

            <div className="ticket_t_miza">
              <div className="ticket_t_miza_label">Miza:</div>
              <div className="ticket_t_miza_section">
                <div className="ticket_t_miza_ccy">RON</div>
                <input
                  type="number"
                  inputMode="decimal"
                  autoComplete="off"
                  className="ticket_t_miza_input"
                  min={1}
                  defaultValue={2}
                  onChange={(e) => {
                    consumer.ticketStake[1](e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="ticket_t_infos">
              <div className="ticket_t_infos_tax">
                Taxa CDA: 5.00% ={" "}
                {((5 / 100) * consumer.ticketStake[0]).toFixed(2)}
              </div>
              <div className="ticket_t_infos_stakeafter">
                Miza dupa aplicarea taxei:{" "}
                {(
                  consumer.ticketStake[0] -
                  (5 / 100) * consumer.ticketStake[0]
                ).toFixed(2)}
              </div>
            </div>
          </div>
        )}
        {consumer.ticketContent[0] != null && (
          <div className="ticket_t_buttons">
            {consumer.ticketContent[0][0].ticketProcInfo && (
              <div className="error_message">
                {consumer.ticketContent[0][0].ticketProcInfo}
                {consumer.tickErrs[0].length > 0 &&
                  consumer.tickErrs[0].map((el, i) => {
                    return <div key={i}>{el}</div>;
                  })}
              </div>
            )}
            <div className="ticket_t_potential">
              <div className="ticket_t_potential_label">Castig potential:</div>
              <div className="ticket_t_potential_value">
                {consumer.ticketContent[0][0].castigMax} <span>RON</span>
              </div>
            </div>
            <div className="ticket_t_print_button">
              <div
                className="ticket_t_print_btn_wrap"
                onClick={() => {
                  !consumer.ticketContent[0][0].ticketProcInfo &&
                    PlaceBet(consumer.spStake[0], consumer.placeBetResponse[1]);
                }}
              >
                PLASEAZA PARIUL
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ticket;
