import React from "react";
import s from "./Info.module.css";

const Info = (props) => {
  const renderInfos = () => {
    return props.response.map((element, i) => {
      if (element.displayIdx === i + 1)
        return (
          <div className={s.infoText}>
            <h2 className={s.hdoi}>
              {element.displayName.toString().replace("_", " ")}
            </h2>
            <p className={s.infoNumber}>
              {props.response !== null ? element.jackpotValue : "RON"}
              <span> RON</span>
            </p>
          </div>
        );
    });
  };

  return <div className={s.info}>{renderInfos()}</div>;
};

export default Info;
