import "./App.css";
import Layout from "./Layout";
import { Route } from "react-router-dom";
import test1 from "./test1";
import test2 from "./test2";
import FrontPage from "./Pages/FrontPage/FrontPage";
import { createContext, useEffect, useState } from "react";
import {
  EvaluateLotoTicket,
  EvaluateTicket,
  GetAuxLotoSerial,
  GetAuxTicketSerial,
} from "./API/ApiManager";
import Sport from "./Pages/Sport/Sport";
import ModalTicket from "./Components/ModalTicket";
import ModalVerif from "./Components/ModalVerif";
import Loto from "./Pages/Loto/Loto";

import "./Mobile.css";
import ModalLoto from "./Components/ModalLoto";
import Results from "./Pages/Results/Results";
import Presentation from "./Pages/Presentation/Presentation";
import Virtuale from "./Pages/Virtuale/Virtuale";
import Contact from "./Pages/Contact/Contact";
import MobileVerification from "./Pages/MobileVerification/MobileVerification";

export const GeneralContext = createContext(null);
function App() {
  const [spStake, setSpStake] = useState(null);
  const [serial, setSerial] = useState(null);

  const [ticketContent, setTicketContent] = useState(null);

  const [ticketStake, setTicketStake] = useState(2);

  const [activeMenuItemSport, setActiveMenuItemSport] = useState({
    id: -1,
    name: "Top 20 Evenimente",
  });
  const [activeSubmenuItemSport, setActiveSubmenuItemSport] = useState(null);

  const [preSelectedFrontPage, setPreSelectedFrontPage] = useState(null);

  const [dateSelector, setDateSelector] = useState([1, 0, 0, 0, 0, 0, 0, 0]);

  const [sportPageSelectedId, setSportPageSelectedId] = useState(-1);

  const [allOddsForEvent, setAllOddsForEvent] = useState(null);

  const [combArray, setCombArray] = useState([]);

  const [placeBetResponse, setPlaceBetResponse] = useState(null);

  const [verifyResponse, setVerifyResponse] = useState(null);

  //Loto Hooks

  const [lotoSerial, setLotoSerial] = useState(null);

  const [lotoPlaceBetResponse, setLotoPlaceBetResponse] = useState(null);

  const [lotoActiveItem, setLotoActiveItem] = useState({
    id: 186,
    max: 16,
    maxBet: 8,
    name: "Grecia Kino",
  });
  const [lotoSelectedEvent, setLotoSelectedEvent] = useState(null);
  const [ltStake, setLtStake] = useState([]);
  const [lotoSelectedDetails, setLotoSelectedDetails] = useState({
    lotteryDate: null,
    lotteryName: null,
    lotterySystem: null,
  });
  const [lotoEvaluate, setLotoEvaluate] = useState(null);
  const [lotoTicketStake, setLotoTicketStake] = useState(2);
  const [checkBoxesArray, setCheckBoxesArray] = useState([]);

  const [activeResultsMenuItem, setActiveResultsMenuItem] = useState(0);
  const [activeResultsName, setActiveResultsName] =
    useState("TOATE SPORTURILE");
  const [dateSelectorRes, setDateSelectorRes] = useState([
    1, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [tickErrs, setTickErrs] = useState([]);

  const contextValue = {
    spStake: [spStake, setSpStake],
    serial: [serial, setSerial],
    ticketContent: [ticketContent, setTicketContent],
    ticketStake: [ticketStake, setTicketStake],
    preSelectedFrontPage: [preSelectedFrontPage, setPreSelectedFrontPage],
    dateSelector: [dateSelector, setDateSelector],
    sportPageSelectedId: [sportPageSelectedId, setSportPageSelectedId],
    activeMenuItemSport: [activeMenuItemSport, setActiveMenuItemSport],
    activeSubmenuItemSport: [activeSubmenuItemSport, setActiveSubmenuItemSport],
    allOddsForEvent: [allOddsForEvent, setAllOddsForEvent],
    combArray: [combArray, setCombArray],
    placeBetResponse: [placeBetResponse, setPlaceBetResponse],
    verifyResponse: [verifyResponse, setVerifyResponse],

    lotoSerial: [lotoSerial, setLotoSerial],
    lotoActiveItem: [lotoActiveItem, setLotoActiveItem],
    lotoSelectedEvent: [lotoSelectedEvent, setLotoSelectedEvent],
    ltStake: [ltStake, setLtStake],
    lotoSelectedDetails: [lotoSelectedDetails, setLotoSelectedDetails],
    lotoEvaluate: [lotoEvaluate, setLotoEvaluate],
    lotoTicketStake: [lotoTicketStake, setLotoTicketStake],
    checkBoxesArray: [checkBoxesArray, setCheckBoxesArray],
    lotoPlaceBetResponse: [lotoPlaceBetResponse, setLotoPlaceBetResponse],

    activeResultsMenuItem: [activeResultsMenuItem, setActiveResultsMenuItem],
    activeResultsName: [activeResultsName, setActiveResultsName],
    dateSelectorRes: [dateSelectorRes, setDateSelectorRes],

    tickErrs: [tickErrs, setTickErrs],
  };

  useEffect(() => {
    setActiveResultsMenuItem(0);
  }, [dateSelectorRes]);

  useEffect(() => {
    if (spStake != null && spStake.eventsArray.length > 3)
      EvaluateTicket(spStake, setTicketContent);
  }, [spStake]);

  useEffect(() => {
    if (combArray.length > 0) {
      let aux = spStake;
      aux.ticketSystem = combArray.join(",");
      EvaluateTicket(aux, setTicketContent);
    } else {
      if (spStake) {
        let aux = spStake;
        aux.ticketSystem = "";
        EvaluateTicket(aux, setTicketContent);
      }
    }
  }, [combArray]);

  useEffect(() => {
    if (spStake != null) {
      let aux = spStake;
      aux.ticketAmount = parseInt(ticketStake);
      EvaluateTicket(aux, setTicketContent);
    }
  }, [ticketStake]);

  useEffect(() => {
    let xx = {
      id: -1,
      name: "Top 20 Evenimente",
    };
    setActiveMenuItemSport(xx);
  }, [dateSelector]);

  useEffect(() => {
    if (placeBetResponse != null) {
      handleModalOpen();
    }
  }, [placeBetResponse]);

  useEffect(() => {
    if (lotoPlaceBetResponse != null) {
      handleLotoModalOpen();
    }
  }, [lotoPlaceBetResponse]);

  useEffect(() => {
    if (verifyResponse != null) {
      handleVerifOpen();
    }
  }, [verifyResponse]);

  const [openModal, setOpenModal] = useState(false);
  const [lotoModal, setLotoModal] = useState(false);

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => {
    setOpenModal(false);
    GetAuxTicketSerial(setSerial);
  };
  const handleLotoModalOpen = () => setLotoModal(true);
  const handleLotoModalClose = () => {
    setLotoModal(false);
    GetAuxLotoSerial(setLotoSerial);
  };

  const [verifModal, setVerifModal] = useState(false);
  const handleVerifOpen = () => setVerifModal(true);
  const handleVerifClose = () => setVerifModal(false);

  useEffect(() => {
    if (ltStake.length > 0) {
      let check = null;
      if (checkBoxesArray.length > 0) {
        check = checkBoxesArray;
      } else {
        if (ltStake.length < lotoActiveItem.maxBet) {
          check = [ltStake.length];
        } else {
          check = [lotoActiveItem.maxBet];
        }
      }

      EvaluateLotoTicket(
        lotoActiveItem.id,
        lotoTicketStake,
        ltStake,
        check,
        setLotoEvaluate
      );
    }
  }, [ltStake]);

  useEffect(() => {
    if (ltStake.length > 0) {
      let check = null;
      if (checkBoxesArray.length > 0) {
        check = checkBoxesArray;
      } else {
        if (ltStake.length < lotoActiveItem.maxBet) {
          check = [ltStake.length];
        } else {
          check = [lotoActiveItem.maxBet];
        }
      }
      EvaluateLotoTicket(
        lotoActiveItem.id,
        lotoTicketStake,
        ltStake,
        check,
        setLotoEvaluate
      );
    }
  }, [lotoTicketStake]);

  useEffect(() => {
    if (checkBoxesArray.length > 0) {
      EvaluateLotoTicket(
        lotoActiveItem.id,
        lotoTicketStake,
        ltStake,
        [checkBoxesArray],
        setLotoEvaluate
      );
    }
  }, [checkBoxesArray]);

  useEffect(() => {
    if (ticketContent != null) {
      let aux = spStake;
      let x = "";
      let y = "";
      let errs = [];
      for (let i = 1; i < ticketContent.length; i++) {
        x += ticketContent[i].eventID + ",";
        y += ticketContent[i].typeCode + ",";
        if (ticketContent[i].action) errs.push(ticketContent[i].action);
      }
      aux.eventsArray = x;
      aux.codesArray = y;
      setSpStake(aux);

      setTickErrs(errs);
    }
  }, [ticketContent]);

  useEffect(() => {
    GetAuxTicketSerial(setSerial);
  }, []);

  useEffect(() => {
    GetAuxLotoSerial(setLotoSerial);
  }, []);
  useEffect(() => {
    console.log(serial);
  }, [serial]);

  return (
    <GeneralContext.Provider value={contextValue}>
      <Layout>
        <Route exact path="/" component={FrontPage} />
        <Route exact path="/sport" component={Sport} />
        <Route exact path="/loto" component={Loto} />
        <Route exact path="/rezultate" component={Results} />
        <Route exact path="/virtuale" component={Virtuale} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/presentation" component={Presentation} />
        <Route exact path="/mverify" component={MobileVerification} />
      </Layout>
      <ModalTicket open={openModal} onClose={handleModalClose} />
      <ModalVerif open={verifModal} onClose={handleVerifClose} />
      <ModalLoto open={lotoModal} onClose={handleLotoModalClose} />
    </GeneralContext.Provider>
  );
}

export default App;
